<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 g">
      <div class="card card-body">
        <h4>
          <i class="fa fa-calendar"></i>
          {{ e("between-attend-report") }}
          -
          <button
            class="btn btn-success"
            v-b-modal.modal-1
            style="border-radius: 0"
          >
            <i class="fa fa-search"></i>
            {{ e("filter") }}
          </button>
          &nbsp;
          <button
            class="btn btn-danger"
            v-if="filter.type != null"
            style="border-radius: 0"
            @click="reload()"
          >
            <i class="fa fa-refresh"></i>
            {{ e("restore-default") }}
          </button>
        </h4>
        <div class="row">
          <div class="col-12 col-lg-4 c g">
            <div class="form-group">
              <label for="">{{ e("date-from") }}</label>
              <input
                v-if="!hijri"
                type="date"
                class="form-control"
                v-model="start"
              />
              <date-picker
                v-if="hijri"
                v-model="start"
                :locale="'ar-sa'"
                :calendar="'hijri'"
              ></date-picker>
              <span v-if="hijri">
                <h2 class="text-danger g text-cneter" v-if="start">
                  {{ start }} هـ
                </h2>
              </span>
            </div>

            <div class="form-group">
              <label for="">{{ e("date-to") }}</label>
              <input
                v-if="!hijri"
                type="date"
                class="form-control"
                v-model="end"
              />
              <date-picker
                v-if="hijri"
                v-model="end"
                :locale="'ar-sa'"
                :calendar="'hijri'"
              ></date-picker>
              <span v-if="hijri">
                <h2 class="text-danger g text-cneter" v-if="end">
                  {{ end }} هـ
                </h2>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="get()">
            <i class="fa fa-search"></i>
            {{ e("search") }}
          </button>
          <br />
          <br />
          <form :action="api + '/reports/download-excel'" method="post">
            <input type="hidden" name="jwt" :value="user.jwt" />
            <input type="hidden" name="from" :value="start" />
            <input type="hidden" name="to" :value="end" />
            {{ e("or") }}
            <button class="btn-link btn btn-lsm">
              <u>
                <i class="fa fa-download"></i>
                {{ e("download-excel-report") }}
              </u>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="reports">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <button class="btn btn-primary" @click="printData">
            <i class="fa fa-print"></i>
            {{ e("print") }}
          </button>
          <br />
          <br />
          <table
            class="table table-hover table-bordered custom-table"
            ref="printTable"
          >
            <thead>
              <th>
                {{ e("name") }}
              </th>
              <th>
                {{ e("number") }}
              </th>
              <th>
                {{ e("total-attends") }}
              </th>
              <th>
                {{ e("total-lates") }}
              </th>
              <th>
                {{ e("total-ghiab") }}
              </th>
              <th>
                {{ e("freedays") }}
              </th>
              <th>
                {{ e("total-lates-minutes") }}
              </th>
              <th class="noprint">
                {{ e("options") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="report in reports" :key="report.member._id">
                <td>
                  {{ report.member.name }}
                </td>
                <td>
                  {{ report.member.number }}
                </td>
                <td>
                  {{ countList(report, 1) }}
                </td>
                <td>
                  {{ countList(report, 2) }}
                </td>
                <td>
                  {{ countList(report, 3) }}
                </td>
                <td>
                  {{ report.freedays.length }}
                </td>
                <td>
                  {{ e("the-attend") }}:
                  {{ report.lates.attend }}
                  {{ e("minute") }}
                  |
                  {{ e("freetime") }}:
                  {{ report.lates.break }}
                  {{ e("minute") }}
                </td>
                <td class="noprint">
                  <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :text="e('options')"
                    variant="relief-secondary btn-sm"
                  >
                    <b-dropdown-item @click="details(report)">
                      <i class="fa fa-list text-success"></i>
                      {{ e("details") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer>
      <div>
        <button class="btn btn-block btn-danger btn-lg" @click="reload()">
          <i class="fa fa-refresh"></i>
          {{ e("restore-default") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByName()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-name") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByNumber()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-number") }}</button
        ><br />
        <button
          @click="filterByPhone()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-phone"></i>
          {{ e("filter-by-phone") }}</button
        ><br />
        <button
          @click="filterByGroup()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-cogs"></i>
          {{ e("filter-by-group") }}</button
        ><br />
        <button
          @click="filterByBranche()"
          v-if="user.branches"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-link"></i>
          {{ e("filter-by-branche") }}
        </button>
      </div>
    </b-modal>
    <b-modal id="modal-2" hide-footer>
      <div>
        <div class="form-group">
          <label for="">{{ e("settings-group") }}</label>
          <select
            class="form-control"
            @change="filterByGroupNow()"
            v-model="filter.value"
          >
            <template v-for="group in groups">
              <option :key="group._id" :value="group._id">
                {{ group.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-3" hide-footer>
      <div>
        <div class="text-center g">
          <button
            class="btn btn-primary btn-sm"
            @click="filterByBrancheNowALL()"
          >
            {{ e("filter-by-all-branches") }}
          </button>
        </div>
        <div class="form-group">
          <label for="">{{ e("branche") }}</label>
          <select
            class="form-control"
            @change="filterByBrancheNow()"
            v-model="filter.value"
          >
            <template v-for="branche in user.branches_list">
              <option :key="branche.id" :value="branche.id">
                {{ branche.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-4" size="lg" hide-footer>
      <div class="col-12 table-responsive">
        <table class="table table-hover">
          <thead>
            <th>
              {{ e("day") }}
            </th>
            <th>
              {{ e("status") }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(r, i) in report.list" :key="i">
              <td>
                {{ i }}
              </td>
              <td>
                <span v-if="!report.freedays.includes(i)">
                  <span class="badge bg-danger" v-if="r == '3'">
                    {{ e("ghiab") }}
                  </span>
                  <span class="badge bg-success" v-if="r == '1'">
                    {{ e("attend") }}
                  </span>
                  <span class="badge bg-warning" v-if="r == '2'">
                    {{ e("late") }}
                  </span>
                </span>
                <span v-if="report.freedays.includes(i)">
                  <span class="badge bg-primary">
                    {{ e("freeday") }}
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueDatetimeJs from "vue-datetime-js";
import Ripple from "vue-ripple-directive";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
import { BOverlay, VBModal, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    datePicker: VueDatetimeJs,
    VBModal,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
      hijri: false,
      reports: null,
      filter: { type: null },
      groups: [],
      report: {},
      start: null,
      end: null,
      api: api,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    if (cdate("2022-02-02").includes("ه")) {
      this.hijri = true;
    } else {
      this.start = new Date().toISOString().split("T")[0];
      this.end = new Date().toISOString().split("T")[0];
    }
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        g.loading = false;
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
            myfreedays,
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },

    groupName(id) {
      var title = null;
      this.groups.forEach((element) => {
        if (element._id == id) {
          title = element.title;
        }
      });
      return title;
    },
    filterByName() {
      var f = prompt(e("filter-by-name"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "name";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByNumber() {
      var f = prompt(e("filter-by-number"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "number";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByPhone() {
      var f = prompt(e("filter-by-phone"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "phone";
        this.filter.value = f;
        this.page = 0;
        this.get();
      }
    },
    filterByGroup() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-2");
      }, 100);
    },
    filterByGroupNow() {
      this.filter.type = "group";
      this.$bvModal.hide("modal-2");
      this.page = 0;
      this.get();
    },
    filterByBranche() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-3");
      }, 100);
    },
    filterByBrancheNowALL() {
      this.filter.type = "all_branches";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.get();
    },
    filterByBrancheNow() {
      this.filter.type = "branche";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.get();
    },
    reload() {
      this.$bvModal.hide("modal-1");
      this.filter.type = null;
      this.filter.value = "";
      this.page = 0;
      this.get();
    },
    get() {
      var g = this,
        _g = this,
        start = this.start,
        end = this.end;
      _g.loading = true;
      if (cdate("2222-22-22").includes("ه")) {
        start = a2e(start);
        start = moment2(start, "iYYYY/iM/iD").format("YYYY-MM-DD");
        end = a2e(end);
        end = moment2(end, "iYYYY/iM/iD").format("YYYY-MM-DD");
      }
      $.post(api + "/reports/between", {
        jwt: this.user.jwt,
        start: start,
        end: end,
        filter: this.filter,
        number: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response.code),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            _g.reports = r.response.arr;
          }
          _g.loading = false;
        })
        .catch((e) => {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error") + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    count(obj) {
      return Object.keys(obj).length;
    },
    countList(list, status) {
      var count = 0;
      for (const property in list.list) {
        if (!list.freedays.includes(property)) {
          if (list.list[property] == status?.toString()) {
            count = count + 1;
          }
        } else {
          if (status == 5) {
          }
        }
      }
      return count;
    },
    details(report) {
      this.report = report;
      this.$bvModal.show("modal-4");
    },
    printData() {
      var divToPrint = this.$refs.printTable;
      var newWin = window.open("");
      newWin.document.write(`
      <style>
      .noprint{
        display: none;
      }
      table, th, td {
        border: 1px solid;
      }
      td,th{
        padding: 4px
      }
      table{
        width: 100%; border-collapse: collapse;
      }
      </style>
      `);
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
  },
};
</script>
